import { Transaction } from '../Programmes/store';
import {
  PaymentsByPageNumber,
  Payment,
  VatPayment,
  Pagination,
  PaginatedPayments,
  PaginatedScheduledPayments,
  ScheduledPayment,
  Pause,
  ScheduledPaymentsByPageNumber,
  Commission,
  CommissionsByPageNumber,
  PaginatedCommissions,
  CommissionAPI,
} from './store';
import dayjs from 'dayjs';

//Standard Payments
export interface PaymentsAPI {
  payments: Payment[];
  pagination: Pagination;
}

export const transformGetPaymentsPayload = (
  response: PaymentsAPI,
  currentPayments: PaginatedPayments | null
): PaginatedPayments => {
  const transformedPayments = response.payments.map((payment: Payment) => ({
    ...payment,
    reporting_date: payment.reporting_date
      ? dayjs(payment.reporting_date).format('YYYY-MM-DD')
      : null,
  }));

  const paymentsPageMap: PaymentsByPageNumber = currentPayments
    ? currentPayments.payments.set(
        response.pagination.page,
        transformedPayments
      )
    : new Map().set(0, transformedPayments);

  return {
    payments: paymentsPageMap,
    pagination: response.pagination,
  };
};

//Standard Payments
export interface CommissionListAPI {
  commissions: CommissionAPI[];
  pagination: Pagination;
}

export const transformGetCommissionListPayload = (
  response: CommissionListAPI,
  currentCommissions: PaginatedCommissions | null
): PaginatedCommissions => {
  const commissionsPageMap: CommissionsByPageNumber = currentCommissions
    ? currentCommissions.commissions.set(
        response.pagination.page,
        transformCommissionList(response.commissions)
      )
    : new Map().set(0, transformCommissionList(response.commissions));

  return {
    commissions: commissionsPageMap,
    pagination: response.pagination,
  };
};

export const transformCommissionList = (
  commissionList: CommissionAPI[]
): Commission[] => {
  return commissionList
    ? commissionList.map((commission: CommissionAPI) =>
        transformCommission(commission)
      )
    : [];
};

const transformCommission = (commissionList: CommissionAPI): Commission => {
  return {
    id: commissionList.id,
    programmeId: commissionList.programme_id,
    programmeName: commissionList.programme_name,
    programmeCurrency: commissionList.programme_currency,
    influencerId: commissionList.influencer_name,
    influencerName: commissionList.influencer_name,
    influencerAvatar: commissionList.influencer_avatar,
    orderDate: commissionList.order_date,
    orderValue: commissionList.order_value,
    orderCommission: commissionList.order_commission,
    orderIdentifier: commissionList.order_identifier,
    status: commissionList.status,
    saleSource: commissionList.sale_source,
  };
};

//Scheduled Payments
export interface ScheduledPaymentAPI {
  id: string;
  influencer_id: string;
  influencer_name: string;
  influencer_avatar: string | null;
  programme_id: string;
  programme_group_id: string | null;
  programme_name: string;
  payment_frequency: string;
  payment_amount: number;
  start_date: string;
  end_date: string;
  payment_reference: string;
  additional_comments: string | null;
  created_at: string;
  created_by_keycloak_id: string;
  approved_at: string | null;
  approved_by_keycloak_id: string | null;
  cancelled_at: string | null;
  cancelled_by_keycloak_id: string | null;
  rejected_at: string | null;
  rejected_by_keycloak_id: string | null;
  last_updated: string;
  pauses: PauseAPI[];
  request_status: string;
  schedule_status: string;
  currency: string;
  vat: VatPayment | null;
}

export interface PauseAPI {
  paused_at: string;
  paused_by_keycloak_id: string;
  resumed_at: string | null;
  resumed_by_keycloak_id: string | null;
}

export interface PaginatedScheduledPaymentsAPI {
  recurring_payments: ScheduledPaymentAPI[];
  pagination: Pagination;
}

const transformPause = (pause: PauseAPI): Pause => {
  return {
    pausedAt: pause.paused_at,
    pausedByKeycloakId: pause.paused_by_keycloak_id,
    resumedAt: pause.resumed_at,
    resumedByKeycloakId: pause.resumed_by_keycloak_id,
  };
};

const transformScheduledPayment = (
  scheduledPayment: ScheduledPaymentAPI
): ScheduledPayment => {
  return {
    id: scheduledPayment.id,
    influencerId: scheduledPayment.influencer_id,
    influencerName: scheduledPayment.influencer_name,
    influencerAvatar: scheduledPayment.influencer_avatar,
    programmeId: scheduledPayment.programme_id,
    programmeGroupId: scheduledPayment.programme_group_id,
    programmeName: scheduledPayment.programme_name,
    paymentFrequency: scheduledPayment.payment_frequency,
    paymentAmount: scheduledPayment.payment_amount,
    startDate: scheduledPayment.start_date,
    endDate: scheduledPayment.end_date,
    paymentReference: scheduledPayment.payment_reference,
    additionalComments: scheduledPayment.additional_comments,
    createdAt: scheduledPayment.created_at,
    createdByKeycloakId: scheduledPayment.created_by_keycloak_id,
    approvedAt: scheduledPayment.approved_at,
    approvedByKeycloakId: scheduledPayment.approved_by_keycloak_id,
    cancelledAt: scheduledPayment.cancelled_at,
    cancelledByKeycloakId: scheduledPayment.cancelled_by_keycloak_id,
    rejectedAt: scheduledPayment.rejected_at,
    rejectedByKeycloakId: scheduledPayment.rejected_by_keycloak_id,
    lastUpdated: scheduledPayment.last_updated,
    pauses: scheduledPayment.pauses.map((pause: PauseAPI) =>
      transformPause(pause)
    ),
    scheduleStatus: scheduledPayment.schedule_status,
    requestStatus: scheduledPayment.request_status,
    currency: scheduledPayment.currency,
    vat: scheduledPayment.vat,
  };
};

export const transformScheduledPayments = (
  recurringPayments: ScheduledPaymentAPI[]
): ScheduledPayment[] => {
  return recurringPayments
    ? recurringPayments.map((recurringPayment: ScheduledPaymentAPI) =>
        transformScheduledPayment(recurringPayment)
      )
    : [];
};

export const transformGetScheduledPaymentsPayload = (
  response: PaginatedScheduledPaymentsAPI,
  currentPayments: PaginatedScheduledPayments | null
): PaginatedScheduledPayments => {
  const paymentsPageMap: ScheduledPaymentsByPageNumber = currentPayments
    ? currentPayments.scheduledPayments.set(
        response.pagination.page,
        transformScheduledPayments(response.recurring_payments)
      )
    : new Map().set(0, transformScheduledPayments(response.recurring_payments));

  return {
    scheduledPayments: paymentsPageMap,
    pagination: response.pagination,
  };
};

export const updateCurrentPaginatedPage = (
  transaction: Transaction,
  currentPaymentPages: PaginatedPayments
): PaginatedPayments => {
  const currentPagePayments = currentPaymentPages?.payments.get(
    currentPaymentPages.pagination.page
  );

  if (currentPagePayments) {
    currentPaymentPages?.payments.set(
      currentPaymentPages.pagination.page,
      currentPagePayments.map(payment => {
        if (payment.transaction_id !== transaction.transactionId)
          return payment;
        return {
          ...payment,
          amount: transaction.transactionCommission.toFixed(2),
          vat: transaction.vat,
        };
      })
    );
  }

  return currentPaymentPages;
};
