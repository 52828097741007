import { Action } from '../state';
import {
  DiscoveryActions,
  DiscoveryState,
  defaultInfluencerQueries,
  defaultInfluencerDetails,
} from './store';
import {
  transformGetInfluencersPayload,
  parseInfluencersPayload,
  parseInfluencerPayload,
  transformSocialAccessPayload,
  transformGetTaxInformationArray,
  transformPostPutMetadataPerProgrammePayload,
  transformDeleteMetadataPayload,
  transformPostPutMetadataPayload,
} from './transformers';

export const DiscoveryReducer = (
  state: DiscoveryState,
  action: Action
): DiscoveryState => {
  switch (action.type) {
    case DiscoveryActions.requestInfluencersData:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencers: true },
        errors: { ...state.errors, getInfluencers: null },
      };
    case DiscoveryActions.setInfluencersData:
      return {
        ...state,
        influencers: parseInfluencersPayload(action.payload),
        isLoading: { ...state.isLoading, influencers: false },
      };
    case DiscoveryActions.requestInfluencersDataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencers: false },
        errors: { ...state.errors, getInfluencers: action.payload },
      };
    case DiscoveryActions.requestInfluencersPaginatedAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencers: true },
        errors: { ...state.errors, getInfluencers: null },
      };
    case DiscoveryActions.requestInfluencersPaginatedSuccess:
      return {
        ...state,
        paginatedInfluencers: transformGetInfluencersPayload(
          action.payload,
          state.paginatedInfluencers?.content || null
        ),
        isLoading: { ...state.isLoading, influencers: false },
      };
    case DiscoveryActions.requestInfluencersPaginatedFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencers: false },
        errors: { ...state.errors, getInfluencers: action.payload },
      };

    case DiscoveryActions.requestInfluencerData:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerData: true },
        errors: { ...state.errors, getInfluencerData: null },
      };
    case DiscoveryActions.setInfluencerData:
      return {
        ...state,
        influencerDetails: parseInfluencerPayload(action.payload),
        isLoading: { ...state.isLoading, influencerData: false },
      };
    case DiscoveryActions.setInfluencerQueries:
      return {
        ...state,
        influencersQueries: { ...state.influencersQueries, ...action.payload },
      };
    case DiscoveryActions.resetInfluencersFilter:
      return {
        ...state,
        influencersQueries: {
          ...defaultInfluencerQueries,
          search: state.influencersQueries.search,
        },
      };
    case DiscoveryActions.requestInfluencerDataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerData: false },
        errors: { ...state.errors, getInfluencerData: action.payload },
      };

    case DiscoveryActions.requestInfluencerInterestTypesAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerInterestTypes: true },
        errors: { ...state.errors, getInfluencerInterestTypes: null },
      };
    case DiscoveryActions.requestInfluencerInterestTypesSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerInterestTypes: false },
        influencerInterestTypes: action.payload,
      };
    case DiscoveryActions.requestInfluencerInterestTypesFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerInterestTypes: false },
        errors: { ...state.errors, getInfluencerInterestTypes: action.payload },
      };
    case DiscoveryActions.requestMetadataTypesAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataTypes: true },
        errors: { ...state.errors, getMetadataTypes: null },
      };
    case DiscoveryActions.requestMetadataTypesSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataTypes: false },
        metadataTypes: action.payload,
      };
    case DiscoveryActions.requestMetadataTypesFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataTypes: false },
        errors: { ...state.errors, getMetadataTypes: action.payload },
      };
    case DiscoveryActions.requestMetadataAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadata: true },
        errors: { ...state.errors, getMetadata: null },
      };
    case DiscoveryActions.requestMetadataSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadata: false },
        metadata: action.payload,
      };
    case DiscoveryActions.requestMetadataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadata: false },
        errors: { ...state.errors, getMetadata: action.payload },
      };
    case DiscoveryActions.requestMetadataPerProgrammeAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataPerProgramme: true },
        errors: { ...state.errors, getMetadataPerProgramme: null },
      };
    case DiscoveryActions.requestMetadataPerProgrammeSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataPerProgramme: false },
        metadataPerProgramme: action.payload,
      };
    case DiscoveryActions.requestMetadataPerProgrammeFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, metadataPerProgramme: false },
        errors: { ...state.errors, getMetadataPerProgramme: action.payload },
      };
    case DiscoveryActions.requestPostMetadataAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: true },
        errors: { ...state.errors, updateMetadata: null },
      };
    case DiscoveryActions.requestPostMetadataSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        metadata: transformPostPutMetadataPayload(
          state.metadata,
          action.payload
        ),
      };
    case DiscoveryActions.requestPostMetadataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        errors: { ...state.errors, updateMetadata: action.payload },
      };
    case DiscoveryActions.requestPutMetadataAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: true },
        errors: { ...state.errors, updateMetadata: null },
      };
    case DiscoveryActions.requestPutMetadataSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        metadata: transformPostPutMetadataPayload(
          state.metadata,
          action.payload
        ),
      };
    case DiscoveryActions.requestPutMetadataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        errors: { ...state.errors, updateMetadata: action.payload },
      };
    case DiscoveryActions.requestPutMetadataPerProgrammeAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: true },
        errors: { ...state.errors, updateMetadata: null },
      };
    case DiscoveryActions.requestPutMetadataPerProgrammeSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        metadataPerProgramme: transformPostPutMetadataPerProgrammePayload(
          state.metadataPerProgramme,
          action.payload
        ),
      };
    case DiscoveryActions.requestPutMetadataPerProgrammeFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        errors: { ...state.errors, updateMetadata: action.payload },
      };
    case DiscoveryActions.requestDeleteMetadataAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: true },
        errors: { ...state.errors, updateMetadata: null },
      };
    case DiscoveryActions.requestDeleteMetadataSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        metadata: transformDeleteMetadataPayload(
          state.metadata,
          action.payload
        ),
      };
    case DiscoveryActions.requestDeleteMetadataFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, updateMetadata: false },
        errors: { ...state.errors, updateMetadata: action.payload },
      };
    case DiscoveryActions.requestGetInstagramDetailsAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: true },
        errors: { ...state.errors, getInstagramDetails: null },
      };
    case DiscoveryActions.requestGetInstagramDetailsSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: false },
        instagramDetails: action.payload,
      };
    case DiscoveryActions.requestGetInstagramDetailsFailure:
      return {
        ...state,
        instagramDetails: null,
        isLoading: { ...state.isLoading, instagramDetails: false },
        errors: {
          ...state.errors,
          getInstagramDetails: action.payload,
        },
      };
    case DiscoveryActions.requestClearCurrentInfluencer:
      return {
        ...state,
        influencerDetails: null,
        metadata: null,
        metadataTypes: null,
      };
    case DiscoveryActions.setSelectedInfluencer:
      return {
        ...state,
        selectedInfluencer: action.payload,
      };
    case DiscoveryActions.clearSelectedInfluencer:
      return {
        ...state,
        selectedInfluencer: null,
      };
    case DiscoveryActions.clearInfluencers:
      return {
        ...state,
        paginatedInfluencers: null,
      };
    case DiscoveryActions.requestGetSocialAccessAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: true },
      };
    case DiscoveryActions.requestGetSocialAccessSuccess:
      return {
        ...state,
        influencerDetails: transformSocialAccessPayload(
          state.influencerDetails,
          action.payload
        ),
        isLoading: { ...state.isLoading, instagramDetails: false },
      };
    case DiscoveryActions.requestGetSocialAccessFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: false },
        errors: {
          ...state.errors,
          getSocialRequirement: action.payload,
        },
      };
    case DiscoveryActions.requestPutSocialAccessAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: true },
      };
    case DiscoveryActions.requestPutSocialAccessSuccess:
      return {
        ...state,
        influencerDetails: transformSocialAccessPayload(
          state.influencerDetails,
          action.payload
        ),
        isLoading: { ...state.isLoading, instagramDetails: false },
      };
    case DiscoveryActions.requestPutSocialAccessFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: false },
        errors: {
          ...state.errors,
          putSocialRequirement: action.payload,
        },
      };
    case DiscoveryActions.requestGetAdminProfileAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: true },
      };
    case DiscoveryActions.requestGetAdminProfileSuccess:
      return {
        ...state,
        influencerDetails: {
          ...defaultInfluencerDetails,
          ...state.influencerDetails,
          lastChangedByName: `${action.payload.first_name} ${action.payload.last_name}`,
        },
        isLoading: { ...state.isLoading, instagramDetails: false },
      };
    case DiscoveryActions.requestGetAdminProfileFailure:
      return {
        ...state,
        isLoading: { ...state.isLoading, instagramDetails: false },
      };
    case DiscoveryActions.requestPostInfluencerTaxInformationAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendTaxInformation: true },
        errors: { ...state.errors, sendTaxInformation: null },
      };
    case DiscoveryActions.requestPostInfluencerTaxInformationSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendTaxInformation: false },
      };
    case DiscoveryActions.requestPostInfluencerTaxInformationFailure:
      return {
        ...state,
        errors: { ...state.errors, sendTaxInformation: action.payload },
        isLoading: { ...state.isLoading, sendTaxInformation: false },
      };
    case DiscoveryActions.requestPutInfluencerTaxInformationAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendTaxInformation: true },
        errors: { ...state.errors, sendTaxInformation: null },
      };
    case DiscoveryActions.requestPutInfluencerTaxInformationSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, sendTaxInformation: false },
      };
    case DiscoveryActions.requestPutInfluencerTaxInformationFailure:
      return {
        ...state,
        errors: { ...state.errors, sendTaxInformation: action.payload },
        isLoading: { ...state.isLoading, sendTaxInformation: false },
      };
    case DiscoveryActions.requestDeleteInfluencerTaxInformationAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, taxInformation: true },
        errors: { ...state.errors, taxInformation: null },
      };
    case DiscoveryActions.requestDeleteInfluencerTaxInformationSuccess:
      return {
        ...state,
        isLoading: { ...state.isLoading, taxInformation: false },
      };
    case DiscoveryActions.requestDeleteInfluencerTaxInformationFailure:
      return {
        ...state,
        errors: { ...state.errors, taxInformation: action.payload },
        isLoading: { ...state.isLoading, taxInformation: false },
      };
    case DiscoveryActions.requestGetInfluencerTaxInformationAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, taxInformation: true },
        errors: { ...state.errors, taxInformation: null },
      };
    case DiscoveryActions.requestGetInfluencerTaxInformationSuccess:
      return {
        ...state,
        taxInformation: transformGetTaxInformationArray(
          action.payload['tax_information']
        ),
        isLoading: { ...state.isLoading, taxInformation: false },
      };
    case DiscoveryActions.requestGetInfluencerTaxInformationFailure:
      return {
        ...state,
        errors: { ...state.errors, taxInformation: action.payload },
        isLoading: { ...state.isLoading, taxInformation: false },
      };
    case DiscoveryActions.requestGetInfluencerRegisteredAtAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, influencerRegistrationDate: true },
        errors: { ...state.errors, influencerRegistrationDate: null },
      };
    case DiscoveryActions.requestGetInfluencerRegisteredAtSuccess:
      return {
        ...state,
        influencerRegistrationDate: action.payload,
        isLoading: { ...state.isLoading },
      };
    case DiscoveryActions.requestGetInfluencerRegisteredAtFailure:
      return {
        ...state,
        errors: {
          ...state.errors,
          influencerRegistrationDate: action.payload,
        },
        isLoading: { ...state.isLoading, influencerRegistrationDate: false },
      };
    case DiscoveryActions.requestGetVatCountriesAttempt:
      return {
        ...state,
        isLoading: { ...state.isLoading, vatCountries: true },
        errors: { ...state.errors, vatCountries: null },
      };
    case DiscoveryActions.requestGetVatCountriesSuccess:
      return {
        ...state,
        vatCountries: action.payload,
        isLoading: { ...state.isLoading, vatCountries: false },
      };
    case DiscoveryActions.requestGetVatCountriesFailure:
      return {
        ...state,
        errors: {
          ...state.errors,
          vatCountries: action.payload,
        },
        isLoading: { ...state.isLoading, vatCountries: false },
      };
    case DiscoveryActions.clearCurrentTaxInformation:
      return {
        ...state,
        taxInformation: null,
        influencerRegistrationDate: null,
      };
    default:
      return state;
  }
};
