import dayjs from 'dayjs';
import { PaginatedMap } from '../../types';
import {
  Paginated,
  Pagination,
  paginationTransformer,
  queryBuilder,
} from '../../utils/pageControllers.utils';
import {
  InfluencerData,
  defaultInfluencerDetails,
  InfluencerTaxInformationAPI,
  InfluencerTaxInformation,
  InfluencerTaxAddressAPI,
  InfluencerTaxAddress,
  MetadataApi,
  Metadata,
  NonInfluencerData,
  UserQueriesAPI,
  CreatedByQueries,
  InfluencerMetadataPerProgramme,
  MetadataPerProgrammeApi,
  EditMetadataPerProgramme,
  EditMetadataPerProgrammePayload,
  WriteInfluencerTaxApi,
} from './store';
import {
  InfluencerSocialAccessModel,
  InfluencerTaxSummary,
  InfluencerTaxSummaryAPI,
  RequestDeleteMetadataAsyncPayload,
} from './types';

export interface InfluencerDataAPI {
  influencer_id: string;
  influencer_first_name: string;
  influencer_last_name: string;
  influencer_address: string;
  influencer_country: string;
  influencer_postcode: string;
  influencer_phone_number: string;
  influencer_interests: string[];
  email_address: string;
  key_cloak_id: string;
  publisher_id: string | null;
  instagram_followers: number | null;
  youtube_subscribers: number | null;
  avatar_url?: string;
  inst_connected: boolean | null;
  yt_connected: boolean | null;
  sentiment_score: number | null;
  sentiment_run_date: string | null;
}
export interface InfluencersAPI {
  influencers: InfluencerDataAPI[];
  pagination: Pagination;
}

export interface PaginatedUserAccountsAPI {
  users: UserAccount[];
  pagination: Pagination;
}

export interface UserAccount {
  keycloak_id: string;
  username: string;
  first_name: string;
  last_name: string;
  created_at: string;
  roles: LevelRolesAPI;
  permissions: LevelPermissionsAPI;
}

export interface LevelRolesAPI {
  global: string[];
  programme_group: { [key: string]: string[] };
  programme: { [key: string]: string[] };
}

export interface LevelPermissionsAPI {
  global: string[];
  programme_group: { [key: string]: string[] };
  programme: { [key: string]: string[] };
}

export const parseInfluencerPayload = (
  response: InfluencerDataAPI
): InfluencerData => {
  return {
    influencerId: response.influencer_id,
    influencerFirstName: response.influencer_first_name,
    influencerLastName: response.influencer_last_name,
    influencerAddress: response.influencer_address,
    influencerCountry: response.influencer_country,
    influencerPostcode: response.influencer_postcode,
    influencerPhoneNumber: response.influencer_phone_number,
    influencerInterests: response.influencer_interests,
    emailAddress: response.email_address,
    keyCloakId: response.key_cloak_id,
    partnerizePublisherId: response.publisher_id,
    instagramFollowers: response.instagram_followers,
    youtubeSubscribers: response.youtube_subscribers,
    avatarUrl: response.inst_connected ? response.avatar_url : null,
    instConnected: response.inst_connected,
    ytConnected: response.yt_connected,
    sentimentScore: response.sentiment_score,
    sentimentRunDate: response.sentiment_run_date,
  };
};

export const parseInfluencersPayload = (
  response: InfluencerDataAPI[]
): InfluencerData[] => {
  return response.map(
    (influencer: InfluencerDataAPI): InfluencerData =>
      parseInfluencerPayload(influencer)
  );
};

export const transformGetInfluencersPayload = (
  response: { content: InfluencersAPI; shouldClear: boolean },
  currentInfluencers: PaginatedMap<InfluencerData> | null
): Paginated<InfluencerData> => {
  const transformer = (api: InfluencerDataAPI[]) => {
    return parseInfluencersPayload(api);
  };
  return paginationTransformer<InfluencerDataAPI, InfluencerData>(
    response.content.pagination,
    response.content.influencers,
    currentInfluencers,
    transformer,
    response.shouldClear
  );
};

export const parseNonInfluencerPayload = (
  response: UserAccount
): NonInfluencerData => {
  return {
    keycloakId: response.keycloak_id,
    firstName: response.first_name,
    lastName: response.last_name,
  };
};

export const parseNonInfluencersPayload = (
  response: UserAccount[]
): NonInfluencerData[] => {
  return response.map(
    (nonInfluencer: UserAccount): NonInfluencerData =>
      parseNonInfluencerPayload(nonInfluencer)
  );
};

export const transformGetNonInfluencersPayload = (
  responseAPI: {
    content: PaginatedUserAccountsAPI;
    shouldClear: boolean;
  },
  currentNonInfluencers: PaginatedMap<NonInfluencerData> | null
): Paginated<NonInfluencerData> => {
  const transformer = () => {
    return parseNonInfluencersPayload(responseAPI.content.users);
  };

  return paginationTransformer<UserAccount, NonInfluencerData>(
    responseAPI.content.pagination,
    responseAPI.content.users,
    currentNonInfluencers,
    transformer,
    responseAPI.shouldClear
  );
};

export function transformUserQueryForBuilder(
  queries: CreatedByQueries
): string {
  return queryBuilder<UserQueriesAPI>({
    limit: queries.limit,
    page: queries.page,
    search_param: queries.name,
  });
}

export const transformGetInvitableInfluencersPayload = (
  response: { content: InfluencersAPI; shouldClear: boolean },
  currentInfluencers: PaginatedMap<InfluencerData> | null
): Paginated<InfluencerData> => {
  const transformer = (api: InfluencerDataAPI[]) => {
    return parseInfluencersPayload(api);
  };
  return paginationTransformer<InfluencerDataAPI, InfluencerData>(
    response.content.pagination,
    response.content.influencers,
    currentInfluencers,
    transformer,
    response.shouldClear
  );
};

export const transformSocialAccessPayload = (
  state: InfluencerData | null,
  payload: InfluencerSocialAccessModel
): InfluencerData | null => {
  return {
    ...defaultInfluencerDetails,
    ...state,
    social_requirement: payload,
  };
};

export const transformGetTaxInformationArray = (
  payload: InfluencerTaxInformationAPI[]
): InfluencerTaxInformation[] => {
  return payload.map(item => {
    return transformGetTaxInformation(item);
  });
};

export const transformTaxAddress = (
  taxAddress: InfluencerTaxAddressAPI | null
): InfluencerTaxAddress | null => {
  if (taxAddress)
    return {
      id: taxAddress.id,
      type: taxAddress.type,
      lineOne: taxAddress.line_one,
      lineTwo: taxAddress.line_two,
      city: taxAddress.city,
      postcode: taxAddress.postcode,
    };
  return null;
};

export const transformGetTaxInformation = (
  payload: InfluencerTaxInformationAPI
): InfluencerTaxInformation => {
  return {
    id: payload.id,
    effectiveFrom: payload.effective_from,
    worksViaAgency: payload.works_via_agency,
    invoiceSubject:
      payload.invoice_subject as WriteInfluencerTaxApi['invoice_subject'],
    influencerTaxCountry: payload.influencer_tax_country,
    influencerVatRegistered: payload.influencer_vat_registered,
    influencerVatNumber: payload.influencer_vat_number,
    influencerHomeAddress: transformTaxAddress(payload.influencer_home_address),
    influencerLimitedCompany: payload.influencer_limited_company,
    influencerCompanyName: payload.influencer_company_name,
    influencerCompanyNumber: payload.influencer_company_number,
    influencerCompanyAddress: transformTaxAddress(
      payload.influencer_company_address
    ),
    agencyTaxCountry: payload.agency_tax_country,
    agencyVatRegistered: payload.agency_vat_registered,
    agencyVatNumber: payload.agency_vat_number,
    agencyLimitedCompany: payload.agency_limited_company,
    agencyCompanyName: payload.agency_company_name,
    agencyCompanyNumber: payload.agency_company_number,
    agencyCompanyAddress: transformTaxAddress(payload.agency_company_address),
    isInitialRecord: payload.is_initial_record,
    shouldIncludeVAT: payload.should_include_vat,
  };
};

export const transformToMetadata = ({
  programme_id,
  programme_title,
  association_accepted_date,
  ...rest
}: MetadataApi): Metadata => {
  return {
    ...rest,
    programmeId: programme_id,
    programmeTitle: programme_title,
    associationAcceptedDate: association_accepted_date,
  };
};

export const transformToMetadataApi = ({
  programmeId,
  programmeTitle,
  associationAcceptedDate,
  ...rest
}: Metadata): MetadataApi => {
  return {
    ...rest,
    programme_id: programmeId,
    programme_title: programmeTitle,
    association_accepted_date: associationAcceptedDate,
  };
};

export const transformToMetadataPerProgramme = ({
  influencer_manager,
  programme_id,
  programme_title,
  partnership_type,
  association_date,
  primary_social,
  additional_socials,
  ...rest
}: MetadataPerProgrammeApi): InfluencerMetadataPerProgramme => {
  return {
    ...rest,
    influencerManager: influencer_manager,
    programmeTitle: programme_title,
    programmeId: programme_id,
    partnershipType: partnership_type,
    associationDate: association_date,
    primarySocial: primary_social,
    additionalSocials: additional_socials,
  };
};

export const transformEditMetadataPayload = (
  editMetadata: EditMetadataPerProgramme,
  programmeId: string
): EditMetadataPerProgrammePayload => {
  return {
    influencerManager: editMetadata.influencer_manager,
    partnershipType: editMetadata.partnership_type,
    tier: editMetadata.tier,
    category: editMetadata.category,
    status: editMetadata.status,
    programmeId: programmeId,
    primary_social: editMetadata.primary_social,
    additional_socials: editMetadata.additional_socials,
  };
};

export const transformPostPutMetadataPayload = (
  state: Metadata[] | null,
  payload: Metadata[]
): Metadata[] => {
  const metadataList = state || [];

  const indexesToReplace = payload.map(updatedMetadata => {
    return metadataList.findIndex(metadata => {
      return (
        metadata.type === updatedMetadata.type &&
        metadata.programmeId == updatedMetadata.programmeId
      );
    });
  });

  return indexesToReplace.reduce((acc, indexToReplace, i) => {
    if (indexToReplace === -1) {
      return [...acc, payload[i]];
    }

    const temp = [...acc];
    temp[indexToReplace] = {
      ...acc[indexToReplace],
      ...payload[i],
    };

    return temp;
  }, metadataList);
};

export const transformPostPutMetadataPerProgrammePayload = (
  state: InfluencerMetadataPerProgramme[] | null,
  payload: EditMetadataPerProgrammePayload
): InfluencerMetadataPerProgramme[] => {
  const metadataList = state || [];

  const indexToReplace = metadataList.findIndex(
    metadata => metadata.programmeId === payload.programmeId
  );

  if (indexToReplace !== -1) {
    metadataList[indexToReplace] = {
      ...metadataList[indexToReplace],
      ...payload,
    };
  }
  return metadataList;
};

export const transformDeleteMetadataPayload = (
  state: Metadata[] | null,
  { type, programmeId }: RequestDeleteMetadataAsyncPayload
): Metadata[] => {
  const metadataList = state || [];

  return metadataList.filter(metadata => {
    return metadata.type !== type || metadata.programmeId != programmeId;
  });
};

export const transformInfluencerTaxSummary = (
  summary: InfluencerTaxSummaryAPI
): InfluencerTaxSummary => {
  return {
    invoiceSubject: summary.invoice_subject,
    isVatRegistered: summary.is_vat_registered,
    shouldIncludeVat: summary.should_include_vat,
    effectiveFrom: dayjs(summary.effective_from),
    country: summary.country,
  };
};
