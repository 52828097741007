import { Paginated } from '../../utils/pageControllers.utils';
import { VATCountry } from './types';

export { DiscoveryReducer } from './reducer';

export * from './actions';

export interface InfluencerData {
  influencerId: string;
  influencerFirstName: string;
  influencerLastName: string;
  influencerAddress: string;
  influencerCountry: string;
  influencerPostcode: string;
  influencerPhoneNumber: string;
  influencerInterests: string[];
  emailAddress: string;
  keyCloakId: string;
  partnerizePublisherId: string | null;
  instagramFollowers: number | null;
  youtubeSubscribers: number | null;
  instConnected: boolean | null;
  ytConnected: boolean | null;
  sentimentScore: number | null;
  sentimentRunDate: string | null;
  lastChangedByName?: string;
  avatarUrl?: string | null;
  social_requirement?: {
    required: boolean;
    instagram_username?: string;
    youtube_username?: string;
    last_changed_by?: string;
    last_changed_at?: string;
  };
}

export interface NonInfluencerData {
  keycloakId: string;
  firstName: string;
  lastName: string;
}

export interface LevelRoles {
  global: string[];
  programmeGroup: { [key: string]: string[] };
  programme: { [key: string]: string[] };
}

export interface LevelPermissions {
  global: string[];
  programmeGroup: { [key: string]: string[] };
  programme: { [key: string]: string[] };
}

export interface AgencyInfluencer {
  influencerId: string;
  influencerFirstName: string;
  influencerLastName: string;
  emailAddress: string;
  instagramFollowers: number | null;
  youtubeSubscribers: number | null;
  instConnected: boolean | null;
  ytConnected: boolean | null;
  avatarUrl: string;
}

export interface InfluencerFilters {
  country: string | null;
  interests: string[] | null;
  minInstagramFollowers: number | null;
  maxInstagramFollowers: number | null;
  minYoutubeSubscribers: number | null;
  maxYoutubeSubscribers: number | null;
  social_needs_reconnect: boolean | null;
  social_never_connected: boolean | null;
}

export const defaultInfluencerFilters: InfluencerFilters = {
  country: null,
  interests: null,
  minInstagramFollowers: null,
  maxInstagramFollowers: null,
  minYoutubeSubscribers: null,
  maxYoutubeSubscribers: null,
  social_needs_reconnect: null,
  social_never_connected: null,
};
export interface InfluencerQueries extends InfluencerFilters {
  page: number;
  limit: number;
  search: string | null;
}
const pageLimits = [15, 30, 60];
export const defaultInfluencerQueries: InfluencerQueries = {
  ...defaultInfluencerFilters,
  page: 0,
  limit: pageLimits[0],
  search: null,
};

export interface CreatedByQueries {
  page: number;
  limit: number;
  name: string | null;
}

export interface UserQueriesAPI extends Omit<CreatedByQueries, 'name'> {
  search_param: string | null;
}

export const defaultCreatedByQueries: CreatedByQueries = {
  page: 0,
  limit: pageLimits[0],
  name: null,
};

export interface MetadataType {
  name:
    | string
    | 'Historic ID'
    | 'Influencer Manager'
    | 'Partnership Type'
    | 'Tier'
    | 'Category'
    | 'Status';
  description: string;
}

export interface Metadata {
  type: MetadataType['name'];
  value: string;
  programmeId?: string | null;
  programmeTitle?: string | null;
  associationAcceptedDate?: string | null;
  state?: string | null;
}

export interface MetadataPerProgrammeApi {
  influencer_manager: string;
  partnership_type: string;
  programme_id: string;
  programme_title: string;
  tier: string | null;
  category: string[];
  status: string | null;
  association_date: string | null;
  primary_social: Social | null;
  additional_socials: Social[];
}

export interface InfluencerMetadataPerProgramme {
  influencerManager: string;
  partnershipType: string | null;
  programmeId: string;
  programmeTitle: string;
  tier: string | null;
  category: string[];
  status: string | null;
  associationDate: string | null;
  primarySocial: Social | null;
  additionalSocials: Social[];
}

export interface Social {
  partnership: string;
  handle: string;
}

export interface EditMetadataPerProgramme {
  influencer_manager: string;
  partnership_type: string | null;
  tier: string | null;
  category: string[];
  status: string | null;
  primary_social: Social | null;
  additional_socials: Social[];
}
export interface EditMetadataPerProgrammePayload {
  influencerManager: string;
  partnershipType: string | null;
  tier: string | null;
  category: string[];
  status: string | null;
  programmeId: string;
  primary_social: Social | null;
  additional_socials: Social[];
}

export interface MetadataApi {
  type: MetadataType['name'];
  value: string;
  programme_id?: string | null;
  programme_title?: string | null;
  association_accepted_date?: string | null;
  state?: string | null;
}

export interface InstagramDetails {
  handle: string;
  profilePictureUrl: string | null;
  biography: string | null;
  forceRefreshFlag: boolean;
  updatedAt: string;
}

export interface DiscoveryState {
  pageLimits: number[];
  influencers: InfluencerData[] | null;
  paginatedInfluencers: Paginated<InfluencerData> | null;

  influencerInterestTypes: string[] | null;

  influencersQueries: InfluencerQueries;
  influencerDetails: InfluencerData | null;
  createdByQueries: CreatedByQueries;

  selectedInfluencer: InfluencerData | null;

  instagramDetails: InstagramDetails | null;

  metadata: Metadata[] | null;
  metadataPerProgramme: InfluencerMetadataPerProgramme[] | null;
  metadataTypes: MetadataType[] | null;

  taxInformation: InfluencerTaxInformation[] | null;
  vatCountries: VATCountry[] | null;

  influencerRegistrationDate: InfluencerRegistrationDate | null;

  isLoading: {
    influencers: boolean;
    influencerData: boolean;
    metadata: boolean;
    metadataPerProgramme: boolean;
    updateMetadata: boolean;
    metadataTypes: boolean;
    instagramDetails: boolean;
    influencerInterestTypes: boolean;
    taxInformation: boolean;
    vatCountries: boolean;
    sendTaxInformation: boolean;
    influencerRegistrationDate: boolean;
  };
  errors: {
    getInfluencerData: string | null;
    getInfluencers: string | null;
    getMetadata: string | null;
    getMetadataPerProgramme: string | null;
    updateMetadata: string | null;
    getMetadataTypes: string | null;
    getInstagramDetails: string | null;
    getInfluencerInterestTypes: string | null;
    getSocialRequirement: string | null;
    putSocialRequirement: string | null;
    taxInformation: string | null;
    vatCountries: string | null;
    sendTaxInformation: string | null;
    influencerRegistrationDate: string | null;
  };
}

export interface GetSubscribersResponse {
  subscribers: number;
  influencer_id: string;
}

export interface WriteInfluencerTaxApi {
  effective_from?: string;
  works_via_agency: boolean;
  invoice_subject: 'influencer' | 'influencer_company' | 'agency';
  influencer_tax_country: string;
  influencer_vat_registered: boolean;
  influencer_vat_number?: string;
  influencer_home_address?: WriteAddressContextApi;
  influencer_limited_company: boolean;
  influencer_company_name?: string;
  influencer_company_number?: string;
  influencer_company_address?: WriteAddressContextApi;
  agency_tax_country?: string;
  agency_vat_registered?: boolean;
  agency_vat_number?: string;
  agency_limited_company?: boolean;
  agency_company_name?: string;
  agency_company_number?: string;
  agency_company_address?: WriteAddressContextApi;
  should_include_vat: boolean;
}

export interface WriteAddressContextApi {
  type: 'influencer_home' | 'influencer_company' | 'agency_company';
  line_one: string;
  line_two?: string;
  city: string;
  postcode: string;
}

export interface InfluencerTaxInformationAPI {
  id: string;
  effective_from: string;
  works_via_agency: boolean;
  invoice_subject: string;
  influencer_tax_country: string;
  influencer_vat_registered: boolean;
  influencer_vat_number: string | null;
  influencer_home_address: InfluencerTaxAddressAPI | null;
  influencer_limited_company: boolean;
  influencer_company_name: string | null;
  influencer_company_number: string | null;
  influencer_company_address: InfluencerTaxAddressAPI | null;
  agency_tax_country: string | null;
  agency_vat_registered: boolean | null;
  agency_vat_number: string | null;
  agency_limited_company: boolean | null;
  agency_company_name: string | null;
  agency_company_number: string | null;
  agency_company_address: InfluencerTaxAddressAPI | null;
  is_initial_record: boolean;
  should_include_vat: boolean;
}

export interface InfluencerTaxInformation {
  id: string;
  effectiveFrom: string;
  worksViaAgency: boolean;
  invoiceSubject: WriteInfluencerTaxApi['invoice_subject'];
  influencerTaxCountry: string;
  influencerVatRegistered: boolean;
  influencerVatNumber: string | null;
  influencerHomeAddress: InfluencerTaxAddress | null;
  influencerLimitedCompany: boolean;
  influencerCompanyName: string | null;
  influencerCompanyNumber: string | null;
  influencerCompanyAddress: InfluencerTaxAddress | null;
  agencyTaxCountry: string | null;
  agencyVatRegistered: boolean | null;
  agencyVatNumber: string | null;
  agencyLimitedCompany: boolean | null;
  agencyCompanyName: string | null;
  agencyCompanyNumber: string | null;
  agencyCompanyAddress: InfluencerTaxAddress | null;
  isInitialRecord: boolean;
  shouldIncludeVAT: boolean;
}

export interface InfluencerTaxAddressAPI {
  id: string | null;
  type: AddressType;
  line_one: string;
  line_two: string | null;
  city: string;
  postcode: string;
}

export interface InfluencerTaxAddress {
  id: string | null;
  type: AddressType;
  lineOne: string;
  lineTwo: string | null;
  city: string;
  postcode: string;
}

export enum AddressType {
  INFLUENCER_HOME = 'influencer_home',
  INFLUENCER_COMPANY = 'influencer_company',
  AGENCY_COMPANY = 'agency_company',
}

export interface InfluencerRegistrationDate {
  registered_at: string | null;
}

export const defaultInfluencerDetails: InfluencerData = {
  influencerId: '',
  influencerFirstName: '',
  influencerLastName: '',
  influencerAddress: '',
  influencerCountry: '',
  influencerPostcode: '',
  influencerPhoneNumber: '',
  influencerInterests: [],
  keyCloakId: '',
  partnerizePublisherId: null,
  emailAddress: '',
  instagramFollowers: null,
  youtubeSubscribers: null,
  instConnected: null,
  ytConnected: null,
  sentimentScore: null,
  sentimentRunDate: null,
};

export const defaultDiscoveryState: DiscoveryState = {
  pageLimits: pageLimits,
  influencers: null,
  paginatedInfluencers: null,
  influencerInterestTypes: null,
  influencersQueries: defaultInfluencerQueries,
  createdByQueries: defaultCreatedByQueries,
  influencerDetails: null,
  selectedInfluencer: null,
  metadata: null,
  metadataTypes: null,
  instagramDetails: null,
  taxInformation: null,
  vatCountries: null,
  influencerRegistrationDate: null,
  metadataPerProgramme: null,

  isLoading: {
    influencers: false,
    influencerData: false,
    metadata: false,
    metadataPerProgramme: false,
    updateMetadata: false,
    metadataTypes: false,
    instagramDetails: false,
    influencerInterestTypes: false,
    taxInformation: false,
    vatCountries: false,
    sendTaxInformation: false,
    influencerRegistrationDate: false,
  },

  errors: {
    getInfluencerData: null,
    getInfluencers: null,
    getMetadata: null,
    updateMetadata: null,
    getMetadataTypes: null,
    getInstagramDetails: null,
    getInfluencerInterestTypes: null,
    getSocialRequirement: null,
    putSocialRequirement: null,
    taxInformation: null,
    vatCountries: null,
    sendTaxInformation: null,
    influencerRegistrationDate: null,
    getMetadataPerProgramme: null,
  },
};
