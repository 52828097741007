import {
  AssociationAPIModel,
  Association,
  InfluencerAPIModel,
  Influencer,
  TransactionApiModel,
  Transaction,
  TransactionStatus,
  Programme,
  ProgrammeAgreementApiModel,
  PaymentType,
  ProgrammeState,
  ProgrammeGroupAPIResponse,
  ProgrammeGroup,
  ProgrammeGroupAPIModel,
  Notification,
  PaginatedAssociationApi,
  ProgrammeQueries,
  ProgrammeQuery,
  PaginatedInvitationsApi,
  Invitation,
  CampaignFormBody,
  CampaignBody,
  CampaignQueries,
  PaginatedCampaignAssociationAPI,
  CampaignAssociation,
  CampaignAssociationAPI,
  CampaignAssociationsQuery,
  CampaignInvitationsQuery,
  InvitationApi,
  ProgrammeCommissionsApi,
  ProgrammeCommissions,
  ProgrammeCommission,
  DiscountCodeApi,
  DiscountCode,
  PaymentScheduleType,
} from './store';
import dayjs from 'dayjs';
import {
  Paginated,
  paginationTransformer,
} from '../../utils/pageControllers.utils';
import { PaginatedMap } from '../../types';
import { transformCampaignAssociationsFromApi } from './transformer';
import { CommissionTarget } from '../../components/common/Commissions/types';

export const getAssociationPayload = (
  response: AssociationAPIModel
): Association => {
  const {
    influencer_id,
    programme_id,
    influencer_discount_code,
    invited_date,
    accepted_date,
    created_by_marketer_id,
    new_customer_commission_rate,
    returning_customer_commission_rate,
    commissions,
    view_commissions,
    first_name,
    last_name,
    avatar_url,
    youtube_connected,
    instagram_connected,
  } = response;
  return {
    influencerId: influencer_id,
    programmeId: programme_id,
    influencerDiscountCode: influencer_discount_code,
    createdByMarketerId: created_by_marketer_id,
    acceptedDate: accepted_date,
    invitedDate: invited_date,
    newCustomerCommissionRate: new_customer_commission_rate,
    returningCustomerCommissionRate: returning_customer_commission_rate,
    commissions: getCommissionPayload(commissions),
    viewCommissions: view_commissions,
    firstName: first_name,
    lastName: last_name,
    avatarUrl: avatar_url,
    youtubeConnected: youtube_connected,
    instagramConnected: instagram_connected,
  };
};

export const getAssociationsPayload = (
  response: AssociationAPIModel[]
): Association[] => {
  return response.map((association: AssociationAPIModel) =>
    getAssociationPayload(association)
  );
};

export const getAssociationsPaginatedPayload = (
  response: { content: PaginatedAssociationApi; shouldClear: boolean },
  currentContent: PaginatedMap<Association> | null
): Paginated<Association> => {
  const transformer = (api: AssociationAPIModel[]) => {
    return api.map(association => getAssociationPayload(association));
  };

  return paginationTransformer<AssociationAPIModel, Association>(
    response.content.pagination,
    response.content.associations,
    currentContent,
    transformer,
    response.shouldClear
  );
};

export const getCampaignAssociationsPaginatedPayload = (
  response: { content: PaginatedCampaignAssociationAPI; shouldClear: boolean },
  currentContent: PaginatedMap<CampaignAssociation> | null
): Paginated<CampaignAssociation> => {
  const transformer = (api: CampaignAssociationAPI[]) => {
    return transformCampaignAssociationsFromApi(api);
  };

  return paginationTransformer<CampaignAssociationAPI, CampaignAssociation>(
    response.content.pagination,
    response.content.associations,
    currentContent,
    transformer,
    response.shouldClear
  );
};

export const getCommissionPayload = (
  response: ProgrammeCommissionsApi,
  desiredTarget?: CommissionTarget
): ProgrammeCommissions => {
  let programmeFallback = response.fallback ? response.fallback : null;

  if (response.campaign_fallback)
    programmeFallback = response.campaign_fallback.reduce(
      (acc, fallback) => {
        if (fallback.target === CommissionTarget.PROGRAMME) return fallback;
        return acc;
      },
      null as ProgrammeCommission | null
    );

  const campaignFallback: ProgrammeCommission | null =
    response.campaign_fallback
      ? response.campaign_fallback.reduce(
          (acc, fallback) => {
            if (fallback.target === CommissionTarget.CAMPAIGN) return fallback;
            return acc;
          },
          null as ProgrammeCommission | null
        )
      : null;

  const categories = desiredTarget
    ? response.categories.map(commission => {
        return { ...commission, target: desiredTarget };
      })
    : response.categories;

  return {
    programmeFallback: programmeFallback,
    campaignFallback: campaignFallback,
    categories: categories,
  };
};

export const getInvitationsPaginatedPayload = (
  response: { content: PaginatedInvitationsApi; shouldClear: boolean },
  currentContent: PaginatedMap<Invitation> | null
): Paginated<Invitation> => {
  const transformer = (api: InvitationApi[]) => {
    return api.map(invitation => {
      return {
        id: invitation.id,
        createdTimestamp: invitation.created_timestamp,
        keycloakId: invitation.keycloak_id,
        programmeId: invitation.programme_id,
        influencerName: invitation.influencer_name,
        createdByMarketerName: invitation.created_by_marketer_name,
        createdByMarketerId: invitation.created_by_marketer_id,
        status: invitation.status,
        commissions: getCommissionPayload(invitation.commissions),
        agreement: invitation.agreement,
        viewCommissions: invitation.view_commissions,
        campaignId: invitation.campaign_id,
      };
    });
  };
  return paginationTransformer<InvitationApi, Invitation>(
    response.content.pagination,
    response.content.invitations,
    currentContent,
    transformer,
    response.shouldClear
  );
};

export const updateAssociationsPayload = (
  response: Association,
  state: Association[] = []
): Association[] => {
  const result = state.reduce((acc: Association[], current: Association) => {
    current.influencerId === response.influencerId
      ? acc.push(response)
      : acc.push(current);
    return acc;
  }, []);

  return result;
};
export const influencersFromApi = (
  influencers: InfluencerAPIModel[]
): Influencer[] => {
  return influencers.map(influencer => {
    return influencer
      ? {
          influencerFirstName: influencer.influencer_first_name,
          influencerLastName: influencer.influencer_last_name,
          influencerAddress: influencer.influencer_address,
          influencerCountry: influencer.influencer_country,
          influencerPostcode: influencer.influencer_postcode,
          influencerPhoneNumber: influencer.influencer_phone_number,
          influencerInterests: influencer.influencer_interests,
          keyCloakId: influencer.key_cloak_id,
          influencerId: influencer.influencer_id,
        }
      : {
          influencerFirstName: 'Error:',
          influencerLastName: 'Not found',
          influencerAddress: 'Not found',
          influencerCountry: 'Not found',
          influencerPostcode: 'Not found',
          influencerPhoneNumber: 'Not found',
          influencerInterests: [],
          keyCloakId: 'Not found',
          influencerId: 'Not found',
        };
  });
};
export const getAssociationsWithInfluencerNamesPayload = (
  response: InfluencerAPIModel[],
  associations: Association[]
) => {
  const influencerIdToNameMap = new Map<string, string>();

  const influencers = influencersFromApi(response);

  influencers.forEach((influencer: Influencer) =>
    influencerIdToNameMap.set(
      influencer.influencerId,
      `${influencer.influencerFirstName} ${influencer.influencerLastName}`
    )
  );
  const namedAssociations = associations.map((association: Association) => ({
    ...association,
    influencerName: influencerIdToNameMap.get(association.influencerId)
      ? influencerIdToNameMap.get(association.influencerId)
      : `Error: Not Found - Id: ${association.influencerId}`,
  }));

  return namedAssociations;
};

export const associationToApi = (association: Association) => {
  const {
    influencerId,
    programmeId,
    influencerDiscountCode,
    createdByMarketerId,
    acceptedDate,
    invitedDate,
  } = association;

  return {
    influencer_id: influencerId,
    programme_id: programmeId,
    influencer_discount_code: influencerDiscountCode,
    created_by_marketer_id: createdByMarketerId,
    accepted_date: acceptedDate,
    invited_date: invitedDate,
  };
};

export const transactionFromApi = (
  transaction: TransactionApiModel
): Transaction => {
  const {
    transaction_id,
    influencer_id,
    programme_id,
    transaction_type,
    transaction_amount,
    transaction_commission,
    transaction_status,
    transaction_created,
    transaction_currency,
    payment_type,
    payment_ref,
    vat,
    reporting_date,
  } = transaction;

  const transactionStatusLookup: { [index: string]: TransactionStatus } = {
    pending_approval: TransactionStatus.PENDING_APPROVAL,
    pending_payment: TransactionStatus.PENDING_PAYMENT,
    complete: TransactionStatus.COMPLETE,
    rejected: TransactionStatus.REJECTED,
  };

  const paymentTypeLookup: { [index: string]: PaymentType } = {
    paid: PaymentType.PAID,
    regular: PaymentType.REGULAR,
  };

  return {
    transactionId: transaction_id,
    influencerId: influencer_id,
    programmeId: programme_id,
    transactionType: transaction_type,
    paymentType: payment_type ? paymentTypeLookup[payment_type] : null,
    paymentRef: payment_ref,
    transactionAmount: transaction_amount,
    transactionCommission: transaction_commission,
    transactionCurrency: transaction_currency,
    transactionStatus: transactionStatusLookup[transaction_status],
    transactionCreated: dayjs(transaction_created),
    vat: vat,
    reportingDate: reporting_date,
  };
};

export const transformGetDiscountCodePayload = (
  response: DiscountCodeApi
): DiscountCode => {
  return {
    id: response.id,
    name: response.name,
    code: response.code,
    influencerId: response.influencer_id,
    validFrom: response.valid_from,
    validTo: response.valid_to,
    isCommissionable: response.is_commissionable,
    programmeId: response.programme_id,
    campaignId: response.campaign_id,
    deletedAt: response.deleted_at,
  };
};

export const transactionsFromApi = (
  transactions: TransactionApiModel[]
): Transaction[] =>
  transactions.map(transaction => transactionFromApi(transaction));

export const handleApproveTransaction = (
  transactions: Transaction[],
  transactionId: string
) => {
  return transactions.map(transaction => {
    return transactionId === transaction.transactionId
      ? { ...transaction, transactionStatus: TransactionStatus.PENDING_PAYMENT }
      : transaction;
  });
};

export const transformGetAgreementPayload = (
  current: Programme | null,
  response: ProgrammeAgreementApiModel
): Programme | null => {
  if (current) {
    current.agreement = response.agreement;
    return current;
  }
  return null;
};

export const transformGetAssociationAgreementPayload = (
  response: ProgrammeAgreementApiModel
) => {
  return {
    programmeId: response.programme_id,
    version: response.version,
    createdAt: response.created_at,
    createdByAdmin: response.created_by_admin,
    agreement: response.agreement,
  };
};

export const transformGetDiscountCodeListPayload = (
  response: DiscountCodeApi[]
) => {
  return response.map(item => {
    return {
      id: item.id,
      name: item.name,
      code: item.code,
      influencerId: item.influencer_id,
      validFrom: item.valid_from,
      validTo: item.valid_to,
      isCommissionable: item.is_commissionable,
      programmeId: item.programme_id,
      campaignId: item.campaign_id,
      deletedAt: item.deleted_at,
    };
  });
};

export const transformPaymentTypesPayload = (
  response: string[]
): PaymentType[] => {
  const paymentTypeLookup: { [index: string]: PaymentType } = {
    paid: PaymentType.PAID,
    regular: PaymentType.REGULAR,
  };

  return response.map(s => paymentTypeLookup[s]);
};

export const transformPaymentTypesToAPI = (val: string) => {
  switch (val) {
    case PaymentType.PAID:
      return 'paid';
    case PaymentType.REGULAR:
      return 'regular';
    default:
      return 'regular';
  }
};

export const transformPaymentTypesFromInput = (val: string) => {
  switch (val) {
    case 'One Off Bonus':
      return PaymentScheduleType.PAID;
    case 'Regular Payment':
      return PaymentScheduleType.REGULAR;
    default:
      return PaymentScheduleType.REGULAR;
  }
};

export const transformProgrammeStateFromAPI = (
  response: string
): ProgrammeState => {
  const programmeStateLookup: { [index: string]: ProgrammeState } = {
    pending: ProgrammeState.PENDING,
    awaiting_live: ProgrammeState.AWAITING_LIVE,
    under_testing: ProgrammeState.UNDER_TESTING,
    live: ProgrammeState.LIVE,
  };

  return programmeStateLookup[response];
};

export const transformProgrammeFromAPI = (response: Programme): Programme => {
  return {
    ...response,
    state: transformProgrammeStateFromAPI(response.state),
  };
};

export const handleRejectTransaction = (
  transactions: Transaction[],
  transactionId: string
) => {
  return transactions.map(transaction => {
    return transactionId === transaction.transactionId
      ? { ...transaction, transactionStatus: TransactionStatus.REJECTED }
      : transaction;
  });
};

export const transformProgrammeGroupFromAPI = (
  progGroup: ProgrammeGroupAPIModel
): ProgrammeGroup => {
  return {
    id: progGroup.id,
    name: progGroup.name,
    createdByAdmin: progGroup.created_by_admin,
  };
};

export const transformProgrammeGroupsFromAPI = (
  response: ProgrammeGroupAPIResponse
): ProgrammeGroup[] => {
  const inProgrammeGroups = response.programme_groups;
  if (!inProgrammeGroups.length) return [];

  return inProgrammeGroups.map(group => transformProgrammeGroupFromAPI(group));
};

export const handleUpdateNotification = (
  notification: Notification,
  notifications: Notification[] | null
): Notification[] | null => {
  const updatedNotifications = notifications?.map(item =>
    item.programme_id === notification.programme_id &&
    item.created_at === notification.created_at
      ? notification
      : item
  );

  return updatedNotifications || null;
};

export const transformToQueries = (
  newPagination: {
    field: 'associations' | 'invitations';
    queries: ProgrammeQuery;
  },
  currentQueries: ProgrammeQueries
) => {
  return { ...currentQueries, [newPagination.field]: newPagination.queries };
};

export const transformToCampaignQueries = (
  newPagination: {
    field: 'associations' | 'invitations';
    queries: CampaignAssociationsQuery | CampaignInvitationsQuery;
  },
  currentQueries: CampaignQueries
) => {
  return { ...currentQueries, [newPagination.field]: newPagination.queries };
};

export const transformNewAssociation = (
  currentAssociations: Paginated<Association>,
  response: { page: number; association: Association }
): Paginated<Association> => {
  const pageOfAssociations = currentAssociations?.content?.get(response.page);
  const newPage =
    pageOfAssociations?.map((association: Association) => {
      return association.influencerId === response.association.influencerId
        ? response.association
        : association;
    }) || [];
  return {
    pagination: currentAssociations.pagination,
    content:
      currentAssociations.content?.set(response.page, newPage) ||
      currentAssociations.content,
  };
};

export const postCampaignPayload = (
  campaignForm: CampaignFormBody
): CampaignBody => {
  const visualDirections = Object.values(
    campaignForm.visual_direction_ids
  ).filter(val => !!val);
  const additionalImages = Object.values(
    campaignForm.additional_images_ids
  ).filter(val => !!val);
  const connectors = Object.values(campaignForm.connectors).filter(
    val => !!val
  );
  const dos = Object.values(campaignForm.campaign_dos).filter(val => !!val);
  const donts = Object.values(campaignForm.campaign_donts).filter(val => !!val);
  const links = campaignForm.urls.filter(val => !!val.title);

  const start_date = new Date(
    Date.parse(`${campaignForm.start_date} ${campaignForm.start_time}`)
  ).toISOString();
  const end_date = new Date(
    Date.parse(`${campaignForm.end_date} ${campaignForm.end_time}`)
  ).toISOString();

  return {
    name: campaignForm.campaign_name,
    brand_name: campaignForm.brand_name,
    brief: campaignForm.campaign_brief,
    start_date: start_date,
    end_date: end_date,
    time_zone: campaignForm.time_zone,
    budget: campaignForm.budget || 0,
    campaign_banner_id: campaignForm.campaign_banner_id,
    dos: dos,
    donts: donts,
    visual_direction: visualDirections,
    additional_images: additionalImages,
    links: links,
    connectors: connectors,
  };
};
