import { BooleanString } from 'types/common.types';

export const voweliser = (followingWord: string) => {
  switch (followingWord.substr(0, 1).toLowerCase()) {
    case 'a':
    case 'e':
    case 'i':
    case 'o':
    case 'u':
      return 'an';

    default:
      return 'a';
  }
};

export const capitalise = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const isBooleanString = (str: string): str is BooleanString => {
  return ['true', 'false'].includes(str);
};

export const booleanStringToBoolean = (str: BooleanString): boolean => {
  return str === 'true';
};

export const renderOptionalString = (value: string | null) => {
  return value ?? 'N/A';
};
